<template>
  <el-dialog
    title=""
    width="500px"
    custom-class="previewDialog"
    :visible.sync="dialogVisible"
    :show-close="false"
    @close="handleClosePay"
  >
    <div class="order-pay">
      <p class="title">请打开微信扫描支付</p>
      <div class="codeBox">
        <div id="qrcode" ref="qrCodeUrl" ></div>
      </div>
      <el-button
        class="btn"
        :class="btnDisabled ? 'disabled' : ''"
        type="primary"
        @click="handleClosePay"
        >取消支付</el-button
      >
      <!-- <el-button
        class="btn"
        :class="btnDisabled ? 'disabled' : ''"
        type="primary"
        :disabled="btnDisabled"
        @click="handleRefreshCode"
        >{{ btnText }}</el-button
      > -->

    </div>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'OrderPay',
  props: {
    pay: Object
  },
  data() {
    return {
      dialogVisible: false,
      btnDisabled: true,
      btnText: '',
      second: 5,
      timer: null,
      refresh: true
    }
  },
  watch: {
    'pay.show': {
      handler(val) {
        if (val) {
          this.dialogVisible = this.pay.show
          setTimeout(this.handleReportPayNotify(), 5000)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.creatQrCode();
    })
  },
  methods: {
    creatQrCode() {
      let qrcode = new QRCode(this.$refs.qrCodeUrl, {
          // text: 'https://www.baidu.com', // 需要转换为二维码的内容
          text: this.pay.code_url, // 需要转换为二维码的内容
          width: 180,
          height: 180,
          colorDark: '#000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
      })
    },

    // 取消支付
    handleClosePay() {
      this.cancelPayFn(); // 取消支付
      this.$emit('closePay', { type: false })
      this.$message.warning('您已取消支付')
    },
    // 取消支付接口
    cancelPayFn() {
      this.$http.post('/user/flex_job/cancelPay', {order_no:this.pay.order_no}).then(res => {
        if(res.code == 1) {
          this.$router.push('/order')
          // 清除定时器
          clearTimeout(this.timer)
        }
      })
    },

    // 监测支付回调结果
    handleReportPayNotify() {
      let num = 0
      this.timer = setInterval(() => {
        num++
        // 定时查询招工详情，查看是否支付
        this.$http.get('/user/flex_job/info', {params:{id: this.pay.job_id} }).then(res => {
          if (res.code == 1 && res.data.info.is_pay == 1) { // is_pay:0未支付，1：支付成功，2：支付中
            this.$emit('closePay', { type: false })
            this.$message.success('支付成功！')
            this.$router.push('/order')
            // 清除定时器
            clearTimeout(this.timer)
          }
        })
        
        // 支付回调测试用（默认支付成功）
        // this.$http.post('/user/flex_job/testNotify',{out_trade_no:this.pay.order_no}).then(res => {
        //   if (res.code == 1) { // is_pay:0未支付，1：支付成功，2：支付中
        //     this.$emit('closePay', { type: false })
        //     this.$message.success('支付成功！')
        //     this.$router.push('/order')
        //     // 清除定时器
        //     clearTimeout(this.timer)
        //   }
        // })
        
        if (num == 60) {
          this.cancelPayFn(); // 取消支付
          this.$emit('closePay', { type: false })
          this.$message.warning('等待时间过长，请在订单列表重新发起支付')
        }
      }, 9000)
    }
  },
  
}
</script>

<style scoped lang="scss">
.previewClass ::v-deep .el-dialog__header {
  display: none !important;
} 
.order-pay {
  text-align: center;
}
.codeBox {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.btn {
  margin-top: 20px;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
</style>