import { sign } from "@/util/sign.js"
const common = {
  deepClone(origin, target) {
    var _this = this;
    var target = target || {};
    for (var prop in target) {
      if (target.hasOwnProperty(prop)) {
        if (target[prop] !== null && typeof target[prop] === 'object') {
          origin[prop] = Object.prototype.toString.call(target[prop]) === '[object Array]' ? [] : {};
          _this.deepClone(origin[prop], target[prop]);
        } else {
          origin[prop] = target[prop];
        }
      }
    }
  }
}

// 正则校验
// 手机号格式 const num = /^[1][3,4,5,7,8][0-9]{9}$/;  
export const REG_EXP = {
  mobilePhone: /^1\d{10}/,
  email: /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
  money: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
  identityCard: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  password: /^(?![0-9]+$)(?![a-zA-Z]+$)(?![~!@#$%^&*()_+/*]+$)[0-9A-Za-z~!@#$%^&*()_+/*]{6,16}$/,
  loginName: /^[a-zA-Z0-9]{6,20}$/,
  integer: /^\d+(\.\d+)?$/,
  // integer: /^[0-9]*[1-9][0-9]*$/,
  num: /^(\-|\+)?\d+(\.\d+)?$/

};


export let exportData = (obj, url)=>{
  obj.timestamp = new Date().getTime();
  obj.token = localStorage.getItem('token');
  let str = '';
  Object.keys(obj).forEach((item)=>{
    str += `&${item}=${obj[item]}`
  })
  let data = sign(obj);
  let _baerUrl = process.env.VUE_APP_BASE_API
  console.log(`${_baerUrl}${url}?sign=${data}${str}`,3)
  window.open(`${_baerUrl}${url}?sign=${data}${str}`)
}

export default common;


